import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = {
  key: 2,
  class: "layout-menuitem-text"
}
const _hoisted_3 = {
  key: 3,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler",
  "aria-hidden": "true"
}
const _hoisted_4 = ["href", "target", "onClick", "onMouseenter"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = {
  key: 2,
  class: "layout-menuitem-text"
}
const _hoisted_7 = {
  key: 3,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler",
  "aria-hidden": "true"
}
const _hoisted_8 = {
  key: 2,
  class: "layout-root-menuitem"
}
const _hoisted_9 = { class: "layout-menuitem-root-text p-text-uppercase" }
const _hoisted_10 = {
  key: 1,
  class: "layout-menu-bottom"
}
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MenuComponent = _resolveComponent("MenuComponent", true)!
  const _directive_tooltip = _resolveDirective("tooltip")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.items)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: _normalizeClass(["layout-menu", [{ navbar: !_ctx.showMenuLabels, sidebar: _ctx.showMenuLabels }]]),
          role: "menu"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (_ctx.visible(item) && !item.separator)
                ? (_openBlock(), _createElementBlock("li", {
                    key: item.label || i,
                    class: _normalizeClass([
          {
            'layout-root-menuitem': _ctx.root,
            'active-menuitem': _ctx.activeIndex === i && !item.disabled,
          },
        ]),
                    role: "menuitem"
                  }, [
                    (item.to)
                      ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: item.to,
                          style: _normalizeStyle(item.style),
                          class: _normalizeClass([item.class, 'p-ripple', { 'p-disabled': item.disabled }]),
                          "active-class": "active-route",
                          target: item.target,
                          exact: "",
                          onClick: ($event: any) => (_ctx.onMenuItemClick($event, item, i)),
                          onMouseenter: ($event: any) => (_ctx.onMenuItemMouseEnter(i))
                        }, {
                          default: _withCtx(() => [
                            (_ctx.showMenuLabels)
                              ? (_openBlock(), _createElementBlock("i", {
                                  key: 0,
                                  class: _normalizeClass(['layout-menuitem-icon', item.icon]),
                                  "aria-hidden": "true"
                                }, null, 2))
                              : _withDirectives((_openBlock(), _createElementBlock("i", {
                                  key: 1,
                                  class: _normalizeClass(['layout-menuitem-icon', item.icon]),
                                  "aria-label": _ctx.$t(item.label),
                                  "aria-hidden": true
                                }, null, 10, _hoisted_1)), [
                                  [
                                    _directive_tooltip,
                                    _ctx.$t(item.label),
                                    void 0,
                                    { right: true }
                                  ]
                                ]),
                            (_ctx.showMenuLabels)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.label), 1))
                              : _createCommentVNode("", true),
                            (_ctx.showMenuLabels && item.items)
                              ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["to", "style", "class", "target", "onClick", "onMouseenter"])), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    (!item.to)
                      ? _withDirectives((_openBlock(), _createElementBlock("a", {
                          key: 1,
                          href: item.url || '#',
                          style: _normalizeStyle(item.style),
                          class: _normalizeClass([item.class, 'p-ripple', { 'p-disabled': item.disabled }]),
                          target: item.target,
                          onClick: ($event: any) => (_ctx.onMenuItemClick($event, item, i)),
                          onMouseenter: ($event: any) => (_ctx.onMenuItemMouseEnter(i))
                        }, [
                          (_ctx.showMenuLabels)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: _normalizeClass(['layout-menuitem-icon', item.icon]),
                                "aria-hidden": "true"
                              }, null, 2))
                            : _withDirectives((_openBlock(), _createElementBlock("i", {
                                key: 1,
                                class: _normalizeClass(['layout-menuitem-icon', item.icon]),
                                "aria-label": _ctx.$t(item.label),
                                "aria-hidden": true
                              }, null, 10, _hoisted_5)), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t(item.label),
                                  void 0,
                                  { right: true }
                                ]
                              ]),
                          (_ctx.showMenuLabels)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.label), 1))
                            : _createCommentVNode("", true),
                          (_ctx.showMenuLabels && item.items)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                            : _createCommentVNode("", true)
                        ], 46, _hoisted_4)), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    (_ctx.root)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(item.label), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_Transition, { name: "layout-menu" }, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_MenuComponent, {
                          items: _ctx.visible(item) && item.items,
                          "parent-menu-active": false,
                          "show-menu-labels": _ctx.showMenuLabels,
                          "menu-active": _ctx.menuActive,
                          "layout-mode": _ctx.layoutMode,
                          onMenuitemClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menuitem-click', $event)))
                        }, null, 8, ["items", "show-menu-labels", "menu-active", "layout-mode"]), [
                          [_vShow, 
              item.items &&
              (_ctx.root && (!_ctx.isSlim() || (_ctx.isSlim() && (_ctx.mobileMenuActive || _ctx.activeIndex !== null)))
                ? true
                : _ctx.activeIndex === i)
            ]
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.visible(item) && item.separator)
                ? (_openBlock(), _createElementBlock("li", {
                    key: 'separator' + i,
                    class: "menu-separator",
                    style: _normalizeStyle(item.style),
                    role: "separator"
                  }, null, 4))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showMenuBottom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _withDirectives((_openBlock(), _createElementBlock("button", {
            class: "c-menu__button",
            exact: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAboutUsClick()))
          }, [
            _createElementVNode("img", {
              id: "app-logo",
              src: _ctx.logoUrl,
              alt: ""
            }, null, 8, _hoisted_11)
          ])), [
            [_directive_ripple]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}