import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "layout-menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MenuComponent = _resolveComponent("MenuComponent")!
  const _component_AboutUsModal = _resolveComponent("AboutUsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      "aria-labelledby": "primary-navigation",
      class: "layout-sidebar",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMenuClick && _ctx.onMenuClick(...args)))
    }, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "logo"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            id: "app-logo",
            class: "logo-image",
            src: _ctx.logoUrl,
            alt: "healz"
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MenuComponent, {
          items: _ctx.model,
          "show-menu-labels": false,
          "layout-mode": _ctx.layoutMode,
          "menu-active": _ctx.active,
          "mobile-menu-active": _ctx.mobileMenuActive,
          root: false,
          "hide-menu-bottom": false,
          onMenuitemClick: _ctx.onMenuItemClick,
          onRootMenuitemClick: _ctx.onRootMenuItemClick,
          onOpenAboutusModal: _ctx.onShowAboutUsModal
        }, null, 8, ["items", "layout-mode", "menu-active", "mobile-menu-active", "onMenuitemClick", "onRootMenuitemClick", "onOpenAboutusModal"])
      ])
    ]),
    _createVNode(_component_AboutUsModal, { ref: "aboutUsModalRef" }, null, 512)
  ], 64))
}