
import { defineComponent, onMounted, PropType, ref, nextTick } from '@vue/runtime-core';
import { computed } from 'vue';
import EventBus from '../event-bus';

export default defineComponent({
  emits: ['root-menuitem-click', 'menuitem-click', 'open-aboutus-modal'],
  props: {
    items: {
      type: Array as PropType<any[]>,
      required: true,
    },
    layoutMode: {
      type: String,
      required: true,
    },
    menuActive: {
      type: Boolean,
      required: true,
    },
    mobileMenuActive: {
      type: Boolean,
      reqired: true,
    },
    showMenuLabels: {
      type: Boolean,
      default: true,
    },
    root: {
      type: Boolean,
      default: false,
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },
    hideMenuBottom: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const activeIndex = ref();
    onMounted(() => {
      EventBus.on('reset-active-index', () => {
        if (isSlim()) {
          activeIndex.value = null;
        }
      });
      nextTick(() => {
        if (props.showMenuLabels && props.parentMenuItemActive) {
          let activeRoute = document.getElementsByClassName('router-link-exact-active')[0];
          let activeRouteUlParent = activeRoute?.parentElement?.parentElement;
          let dropDownSibiling: any = activeRouteUlParent?.parentElement?.getElementsByClassName('drop-down')[0];
          if (dropDownSibiling) {
            dropDownSibiling?.click();
          }
        }
      });
    });

    const onMenuItemClick = (event: Event, item: any, index: number) => {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
        event.preventDefault();
      }
      if (item.items) {
        event.preventDefault();
      }
      if (props.root) {
        emit('root-menuitem-click', {
          originalEvent: event,
        });
      }
      if (item.items) {
        activeIndex.value = index === activeIndex.value ? null : index;
      }
      emit('menuitem-click', {
        originalEvent: event,
        item: item,
      });
    };

    const onMenuItemMouseEnter = (index: number) => {
      if (props.root && props.menuActive && props.layoutMode === 'slim' && !isMobile()) {
        activeIndex.value = index;
      }
    };
    const visible = (item: any) => {
      return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    };
    const isMobile = () => {
      return window.innerWidth <= 991;
    };
    const isSlim = () => {
      return props.layoutMode === 'slim';
    };
    const showMenuBottom = computed(() => !props.hideMenuBottom);

    const onAboutUsClick = () => {
      emit('open-aboutus-modal', true);
    };

    const logoUrl = ref(window.location.origin + '/assets/layout/images/logo-about.svg');

    return {
      activeIndex,
      onMenuItemClick,
      onMenuItemMouseEnter,
      visible,
      isMobile,
      isSlim,
      onAboutUsClick,
      logoUrl,
      showMenuBottom,
    };
  },
});
